/**
 * Layout component contains header and footer
 *
 * @package gatsby-wordpress-theme
 */

import React, { useState } from "react";
import PropTypes from "prop-types";

import "./../../sass/common.scss";

import Header from "../header";
import Footer from "../footer";
import classnames from 'classnames'
import ThemeContext from "../../context/ThemeContext";


const Layout = ({ children, className }) => {
  const layoutClassnames = classnames('main-container', {
    [className]: !!className
  })
  return (
    <>
      <Header />
      <main className={layoutClassnames}>{children}</main>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
