/**
 * External dependencies.
 */
import PropTypes from 'prop-types';
import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
/**
 * Internal dependencies.
 */
import defaultSiteLogoUrl from "../../images/logo.png";
import Link from "gatsby-link";
import Nav from "./nav";
import './style.scss'

/**
 * Default Header Component Export.
 *
 * @return {*}
 */
export default ( props ) => {

	return (
		<StaticQuery
			query={ graphql`
				    query HeaderQuery {
					  wp {
					    header: getHeader {
					      siteLogoUrl
					      siteTagLine
					      siteTitle
					      favicon
					    }
					  }
					  headerMenuItems: allWpMenuItem(filter: {locations: {eq: HCMS_MENU_HEADER}}) {
					    edges {
					      node {
					        id
					        databaseId
					        title
					        url
					        label
					        childItems {
					          nodes {
					            id
					            databaseId
					            label
					            url
					          }
					        }
					      }
					    }
					  }
				    }
				` }
			render={ data => {

				const {
					wp: {
						header: {siteTitle, siteTagLine, siteLogoUrl},
					},
					headerMenuItems,
				} = data;
				const siteLogoURL = siteLogoUrl ? siteLogoUrl : defaultSiteLogoUrl;

				return (
						<header className="site-header-container container">
							<div className="site-header">
								<div className="site-brand">
									<Link to="/">
										<figure>
											<img
													className="site-brand__logo"
													src={siteLogoURL}
													width="68"
													height="55"
													alt="header logo"
											/>
										</figure>
									</Link>
									<div className="site-brand__content">
										<h2 className="screen-reader-text site-brand__title">
											{siteTitle}
										</h2>
										<p className="site-brand__description">{siteTagLine}</p>
									</div>
								</div>

								<Nav headerMenuItems={headerMenuItems}/>
							</div>
						</header>
				)

			} }
		/>
	)
}
